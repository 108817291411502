<template>
    <div>
        <template v-if="!invalidInput && jsonObject">
            <div class="row" v-for="row in jsonFields">
                <template v-if="row.length > 1">
                    <div v-for="col in row" :class="col[0].colClass">
                        <JSONElement ref="jsonElement" v-for="field in col" :field="field" v-model="jsonObject[field.name]" :readonly="readonly">
                            <template #richTextEditor="{ value }">
                                <slot name="richTextEditor" :value="value" :fieldName="field.name"></slot>
                            </template>
                        </JSONElement>
                    </div>
                </template> 
                <template v-else>
                    <JSONElement ref="jsonElement" v-for="field in row[0]" :field="field" v-model="jsonObject[field.name]" :readonly="readonly">
                            <template #richTextEditor="{ value }">
                                <slot name="richTextEditor" :value="value" :fieldName="field.name"></slot>
                            </template>
                    </JSONElement>
                </template>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, defineExpose } from 'vue';
import JSONElement from './JSON.FormElement.vue';

export interface IProps {
    definition: string | JSONDefinition,
    readonly?: boolean,
    jsonSpace?: number,
};

const props = withDefaults(defineProps<IProps>(), {
    jsonSpace: 0
});

const model = defineModel<string>();

const jsonFields = computed(() => {
    const def = typeof props.definition == 'string'
        ? JSON.parse(props.definition) as JSONDefinition
        : props.definition ?? [];
    const rows = new Map<number, Map<number, JSONField[]>>();
    def?.fields?.forEach(field => {
        const rowIndex = field.row ?? 0;
        if (!rows.has(rowIndex)) {
            rows.set(rowIndex, new Map());
        }
        const cols = rows.get(rowIndex)!;
        const colIndex = field.col ?? 0;
        if (!cols.has(colIndex)) {
            cols.set(colIndex, []);
        }
        cols.get(colIndex)!.push(field);
    });

    return Array.from(rows.values()).map(cols => {
        return Array.from(cols.values())
    });
});

const jsonString = ref('');
const jsonObject = ref<object>();
const invalidInput = ref(false);
const jsonElement = ref(null);

function updateObject() {
    if (typeof jsonString.value === 'string') {
        try {
            const value = JSON.parse(jsonString.value)
            if (typeof value !== 'object') { throw new TypeError('Could not parse JSON string'); }
            jsonObject.value = value;
            invalidInput.value = false;
        } catch (error) {
            jsonObject.value = {};
            invalidInput.value = false;
            // invalidInput.value = true;
        }
    } else {
        jsonObject.value = jsonString.value;
        invalidInput.value = false;
    }
};

function updateInput() {
    if (typeof model.value === 'string') {
        jsonString.value = model.value || '';
        updateObject();
    } else if (model.value == null) {
        jsonObject.value = {};
        jsonString.value = '{}';

    } else {
        jsonObject.value = model.value;
        jsonString.value = JSON.stringify(model.value, undefined, props.jsonSpace);
    }
}

watch(() => model.value, () => {
    updateInput();
});
watch(jsonString, updateObject);
watch(jsonObject, () => {
    if (props.readonly) { return; }
    const emptyValue = jsonObject.value == null || Object.keys(jsonObject.value).length === 0;
    if (emptyValue) {
        model.value = '';

    } else {
        Object.keys(jsonObject.value).forEach(key=>{
            if(jsonObject.value[key] == ""){
                jsonObject.value[key] = null;
            }
        })
        model.value = JSON.stringify(jsonObject.value, undefined, props.jsonSpace);

    }
}, { deep: true });

onMounted(() => {
    updateInput();
})

function updateRichTextValue(value, name) {
    jsonObject.value[name] = value;
}

defineExpose({ updateRichTextValue });
</script>

<script lang="ts">
export type JSONLookupValue = {
    Value: string,
    Text: string
}

export type JSONFieldType = {
    type: 'text',
    element: 'input'|'textarea'|'div'
} | {
    type: 'number',
    element: undefined,
} | {
    type: 'datetime',
    element: undefined,
} | {
    type: 'time',
    element: undefined,
} | {
    type: 'date',
    element: undefined,
} | {
    type: 'checkbox',
    element: undefined,
} | {
    type: 'radio',
    element: undefined,
} | {
    type: 'select',
    element: undefined,
} | {
    type: 'lookup',
    element: undefined,
} | {
    type: 'content',
    element: 'p'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6'|'span'|'div',
} | {
    type: 'multiselect',
    element: undefined,
} | {
    type: 'multiCheckboxSelect',
    element: undefined,
};

export type JSONDefinition = {
    fields: ({
        name: string,
        title: string,
        caption: string,
        row: number,
        cssClass: string,
        required: boolean,
        maxLength: number,
        col: number,
        colClass: string,
        values?: JSONLookupValue[],
        mergeIntoDescription: boolean,
        UseRichText: boolean,
        inputEditorConfig?: object,
        inputEditor?: string,
    } & JSONFieldType)[]  
};

export type JSONField = JSONDefinition['fields'][0];
</script>